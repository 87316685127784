import auth from "@/api/auth/index";
import { getCookie, setCookie, removeCookie } from "@/helper/cookies";

const actions = {
  async checkLocation() {
    try {
      return await auth.checkLocation();
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async doLogin({ commit }, user) {
    try {
      let response = await auth.doLogin(user);
      if (Object.keys(response).length > 0) {
        await commit("storage/setSession", response.session, { root: true });
        await commit(
          "storage/set",
          { key: "session", value: response.session },
          { root: true }
        );
        await commit(
          "storage/set",
          { key: "firstName", value: response.firstName },
          { root: true }
        );
        await commit(
          "storage/set",
          { key: "lastName", value: response.lastName },
          { root: true }
        );
        return response;
      }
      throw new Error("");
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async doLogout(state, session) {
    try {
      return await auth.doLogout(session);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async sendResetMail(state, user) {
    try {
      return await auth.sendResetMail(user);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async checkResetToken(state, token) {
    try {
      return await auth.checkResetToken(token);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async passwordReset(state, user) {
    try {
      return await auth.passwordReset(user);
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
