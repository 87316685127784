import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueCookieNext } from "vue-cookie-next";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueSocials from "vue-socials";

// Vue Select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// Custom CSS
import "./assets/css/variable.css";
import "./assets/css/tailwind.css";
import "./assets/css/main.scss";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { faCalendar, faClockFour } from "@fortawesome/free-regular-svg-icons";
import {
  faPlus,
  faMinus,
  faEnvelope,
  faPhone,
  faCircleExclamation,
  faCaretDown,
  faBars,
  faXmark,
  faHouse,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(
  faCalendar,
  faPlus,
  faMinus,
  faEnvelope,
  faPhone,
  faFacebookSquare,
  faInstagramSquare,
  faCircleExclamation,
  faCaretDown,
  faBars,
  faXmark,
  faHouse,
  faLocationDot,
  faClockFour
);

VueCookieNext.config({ expire: "2h" });

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("v-select", vSelect)
  .use(store)
  .use(router)
  .use(VueReCaptcha, { siteKey: "6LewtSkpAAAAAFYXpmAdloZy-Oi0vIKd-miFYFdm" })
  .use(VueCookieNext)
  .use(VueSocials)
  .mount("#app");
